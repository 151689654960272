import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./services/store";
import Home from "./routes/index.jsx";
import AboutUs from "./routes/Viewer.jsx";
import Water from "./routes/talk.jsx";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./login";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/quienes-somos-nosotros",
    element: <AboutUs />,
  },
  {
    path: "/agua",
    element: <Water />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
